.esri-widget {
    background-color: #1F0318 !important;
    color: white !important;
  }
  .esri-widget__heading {
    color: white !important;
    margin-left: 400px !important;
  }

 .esri-feature__content-element, .esri-features__content-container{
    padding: 0 !important;
 }

 .esri-features__container{
    border-top: 1px solid #F2C94C
 }
.esri-features__content-feature .interaction-container .container header.header-container{
    display: flex;
    justify-content: center;
    text-align: center;
  }

 .esri-feature-content .rounded-tl-3xl, .esri-feature-content .rounded-tr-3xl{
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
 }
 .esri-popup__main-container, .esri-widget--panel, .esri-popup {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
}

 .esri-feature-content .bg-boundsPurple-400 {
    background-color: black !important;
 }

 .esri-feature-content .bg-boundsPurple-500{
    background-color: #1F0318 !important;
 }