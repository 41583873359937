.rainbow-bar {
  height: 20px;
  width: 100%;
  border-radius: 10px;
  // background: linear-gradient(90deg, magenta 0%, blue 25%, cyan 35%, lime 60%, yellow 75%, orange 85%, red 100%);
  background: linear-gradient(
    to right,
    #e6ffee,
    /* Light green */ #008000,
    /* Green */ #bfff00,
    /* Light yellow-green */ #ffff00,
    /* Yellow */ #ff8000,
    /* Orange */ #ff0000,
    /* Red */ #cc3300,
    /* Darker red */ #999999 /* Gray */
  );
}
