.select-container {
  [class$="-placeholder"] {
    margin-left: 0;
  }

  [class$="-singleValue"] {
    margin-left: 0;
  }

  [class$="-multiValue"]:first-child {
    [class$="-MultiValueGeneric"] {
      padding-left: 0;
    }
  }
}